<template>
  <div class="guest-summary" v-if="occupancy">
    <div class="summary-header">
        <i18n
          path="have_guests"
          tag="h3"
          scope="global"
          class="main-title pb-4 d-flex align-center"
        >
          <template v-if="loading" #number>
            <v-skeleton-loader type="heading" width="45px" class="mx-2" />
          </template>
          <template v-else-if="hasTenantsFlow" #number>
            {{ tenants.length }}
          </template>
          <template v-else #number>
            {{ guests.length }}
          </template>
          <template #type>
            {{ hasTenantsFlow ? $t("tenants").toLowerCase() :  $t("guests").toLowerCase() }}
          </template>
        </i18n>
      <v-row class="mt-n6" v-if="!hasTenantsFlow">
        <v-col>
          <div class="summary-subtitle">{{ $t("cars") }}</div>
          <v-skeleton-loader v-if="loading" type="text" max-width="50%" />
          <div class="summary-stat" v-else>{{ carsLength }}</div>
        </v-col>
        <v-col v-if="!isCyber">
          <div class="summary-subtitle">{{ $t("spaces") }}</div>
          <v-skeleton-loader v-if="loading" type="text" max-width="50%" />
          <div class="summary-stat" v-else>{{ occupancy.total_monthly }}</div>
        </v-col>
      </v-row>
    </div>

    <template v-if="loading">
      <div v-for="i in 5" :key="i" class="guest-record">
        <v-skeleton-loader type="heading" class="mb-2" />
        <v-skeleton-loader type="text" max-width="30%" />
      </div>
    </template>
    <div class="content" v-if="!loading">
      <template v-if="hasTenantsFlow">
        <div
          v-for="tenant in tenants.filter((i) => i.first_name).slice(0, 5)"
          :key="tenant.user_id"
          class="guest-record"
        >
          <h3 class="summary-title mr-4">
            {{ tenant.first_name }} {{ tenant.last_name }}
          </h3>
          <div class="streaming-subtitle">
            {{
              $tc("visitor_qty", tenant.visits.length, {
                n: tenant.visits.length,
              })
            }}
          </div>
        </div>
      </template>
      <template v-if="isCyber">
        <div
          v-for="guest in cyber_users.filter((i) => i.guest_name).slice(0, 5)"
          :key="guest.id"
          class="guest-record"
        >
          <h3 class="summary-title mr-4">{{ guest.guest_name }}</h3>
          <div class="streaming-subtitle">
            {{
              $tc("car_qty", guest.cars.length, {
                n: guest.cars.length,
              })
            }}
          </div>
        </div></template
      >
      <template v-else>
        <div
          v-for="guest in guests.slice(0, 5)"
          :key="guest.guest_id"
          class="guest-record"
        >
          <h3 class="summary-title mr-4" v-if="guest.first_name">
            {{ guest.first_name }} {{ guest.last_name }}
          </h3>
          <h3 class="summary-title mr-4" v-else>
            {{ guest.cars[0] ? guest.cars[0].car_plate : "" }}
          </h3>
          <div class="streaming-subtitle">
            {{
              $tc("car_qty", guest.cars.length, {
                n: guest.cars.length,
              })
            }},
            {{
              $tc("spaces_qty", guest.spaces.length, {
                n: guest.spaces.length,
              })
            }}
          </div>
        </div></template
      >
    </div>
    <div class="summary-footer" v-if="guests.length ">
      <v-btn text class="flat-btn" @click="$router.push('/platform/your-parking')">{{
        $t("view_all")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuestSummary",
  props: {
    place: {
      default: null,
    },
  },
  computed: {
    place_id() {
      return this.place || this.$store.state.app.places[0].place_id;
    },
    guests() {
      return [...this.$store.state.guests.guest_list].filter(
        (g) => g.place_id === this.place_id
      );
    },
    cyber_users() {
      return [...this.$store.state.guests.guest_list];
    },
    isCyber() {
      return this.$store.getters["app/isCyber"];
    },
    hasTenantsFlow() {
      return this.$store.getters["app/hasTenantsFlow"];
    },
    tenants() {
      return [...this.$store.state.tenants.list];
    },
    loading() {
      return this.$store.state.guests.loading;
    },
    carsLength() {
      let counter = 0;
      if (this.isCyber) {
        this.cyber_users.map((guest) => {
          counter = counter + guest.cars.length;
        });
      } else {
        this.guests.map((guest) => {
          counter = counter + guest.cars.length;
        });
      }
      return counter;
    },

    occupancy() {
      let data = [...this.$store.state.dashboard.occupancy];
      if (this.place) {
        data = data.filter((p) => p.place_id === this.place)[0] || {};
      } else {
        if (this.$store.getters["app/isCompany"]) {
          data = data.filter((p) => p.place_id === 0)[0] || {};
        } else {
          data = data[0] || {};
        }
      }
      return data;
    },
  },
};
</script>
