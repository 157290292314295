<template>
  <div class="tabs-content parking-history">
    <h3 class="main-title">{{ $t("parking_history") }}</h3>
    <div class="my-6 d-flex flex-wrap flex-lg-nowrap justify-center align-center">
      <v-tabs v-model="tab" class="mx-0 mr-6">
        <v-tab
          @click.stop
          v-for="(item, index) in items"
          :key="index"
          :style="index === 0 ? { width: '130px', padding: '0' } : ''"
          >{{ item }}
          {{
            index === 0 ? (current?.length ? "(" + current?.length + ")" : "") : ""
          }}</v-tab
        >
      </v-tabs>
      <div class="d-flex justify-center flex-wrap flex-lg-nowrap" style="flex-grow: 1">
        <v-text-field
          v-model="search"
          autocomplete="off"
          @focus="isTyping = true"
          @blur="isTyping = false"
          class="parking-search mb-4 mb-sm-0"
          solo
          :placeholder="$t('search_core')"
        ></v-text-field>
        <CoreFilter
          v-if="!simplified"
          ref="coreFilter"
          @filterSet="updFilters"
          @hide="openFilterForm = false"
          @show="openFilterForm = true"
          :hidden="{
            campaign: true,
            created_by: true,
            price_range: true,
            zone: true,
            status: true,
            wallet: true,
          }"
        />
        <ReportDownloadForm
          v-if="!simplified"
          @hideEmit="openReportForm = false"
          @showEmit="openReportForm = true"
          ref="modal"
          view="parking_history"
        />
        <!-- refresh btn -->
        <v-btn text class="mr-auto dwl-btn" @click.stop="updateTable">
          <img
            :style="{ height: '24px', width: '24px' }"
            src="@/assets/images/refresh.svg"
            alt="refresh"
          />
        </v-btn>
        <v-btn class="main-btn px-4" @click="openAddForm" v-show="hasHistoryEditRight"
          >{{ $t("add_parking_record") }}</v-btn
        >
      </div>
    </div>
    <TableRecord
      :isShort="false"
      class="mb-5"
      :searchValue="search"
      :tab="tab"
      ref="table"
      :openForm="openForm"
    />
    <ParkingRecordPopup
      @hide="resetRecordForm"
      :record="defaultRecord"
      ref="recordForm"
    />
  </div>
</template>

<script>
export default {
  name: "ParkingHistory",
  components: {
    TableRecord: () => import("@/components/ParkingHistory/TableRecord"),
    ParkingRecordPopup: () => import("@/components/ParkingHistory/ParkingRecordPopup.vue"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
    CoreFilter: () => import("@/components/core/Filter"),
  },
  data() {
    return {
      tab: 3,
      openReportForm: false,
      openFilterForm: false,
      openRecordForm: false,
      debouncedInput: "",
      timeout: null,
      updateTimer: null,
      isTyping: false,
      defaultRecord: {
        car: {},
        parking: { guest_type: undefined },
        user: {},
        place: {},
      },
    };
  },
  computed: {
    openForm() {
      return this.openReportForm || this.openFilterForm || this.openRecordForm;
    },
    items() {
      return this.$t("parking_history_tabs");
    },
    occupancy() {
      let data = [...this.$store.state.dashboard.occupancy];

      if (this.$store.getters["app/isCompany"]) {
        data = data.filter((p) => p.place_id === 0)[0] || {};
      } else {
        data = data[0] || {};
      }
      let result = 0;
      result = data.occupied_monthly + data.occupied_hourly;
      return result;
    },
    loading() {
      return this.$store.state.history.parking_history.loading;
    },
    current() {
      return this.$store.state.history.parking_history.current;
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          this.$refs.table.search(this.debouncedInput);
        }, 1000);
      },
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    status() {
      return this.$store.state.history.status;
    },
    simplified() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    hasHistoryEditRight() {
      return this.$store.getters["app/hasHistoryEditRight"];
    },
  },
  watch: {
    status(newValue) {
      if (newValue) {
        clearInterval(this.updateTimer);

        this.updateTimer = setInterval(() => {
          if (
            this.$store.getters["app/authorized"] &&
            this.$refs.table &&
            document.documentElement.scrollTop === 0
          ) {
            this.updateTable();
          }
        }, 60000);
      }
    },
  },
  methods: {
    resetRecordForm() {
      this.openRecordForm = false;
      this.defaultRecord = {
        car: {},
        parking: { guest_type: undefined },
        user: {},
        place: {},
      };
    },
    updFilters(filters) {
      this.$refs.table.filter(filters);
    },
    updateTable() {
      this.$refs.table.updateParams({
        ...this.$refs.table.params,
        from: this.$refs.table.newFrom(),
        to: this.$refs.table.newTo(),
      });
    },
    openAddForm() {
      this.$refs.recordForm.show();
      this.openRecordForm = true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("history/set_loader", true);
      let watcher = setInterval(() => {
        if (vm.$store.state.app.places.length) {
          clearInterval(watcher);
          vm.updateTable();
        }
      }, 500);
      vm.updateTimer = setInterval(() => {
        if (
          vm.$store.getters["app/authorized"] &&
          vm.$refs.table &&
          document.documentElement.scrollTop === 0 &&
          vm.isTyping === false
        ) {
          vm.updateTable();
        }
      }, 60000);
    });
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.updateTimer);
    this.updateTimer = null;
    this.$store.commit("history/set_loader", true);
    next();
  },
};
</script>
