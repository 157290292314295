const whitelabelConfig = {
    'parkingbnb': {
        'brand': 'Parkingbnb',
        'images': './src/assets/images/whitelabel/parkingbnb',
        'styles': './src/scss/whitelabel/parkingbnb',
        'logoWhiteUrl': 'https://storage.googleapis.com/parkingbnb-html-images/logoWhite.svg',
        'logoUrl': 'https://storage.googleapis.com/parkingbnb-html-images/logo%20green.svg',
        'loginImageUrl': 'https://storage.googleapis.com/parkingbnb-html-images/Login_BG.svg',
        'mainColor': '#00746b',
        'lightColor': '#009788',
        'privacyPolicyUrl': 'https://parkingbnb.world/privacy-policy/',
        'tncUrl': 'https://parkingbnb.world/terms-and-conditions/',
    },
    'urbanportal': {
        'brand': 'UrbanPortal',
        'images': './src/assets/images/whitelabel/urbanportal',
        'styles': './src/scss/whitelabel/urbanportal',
        'logoWhiteUrl': 'https://storage.googleapis.com/parkingbnb-html-images/urbanportal_logo.svg',
        'logoUrl': 'https://storage.googleapis.com/parkingbnb-html-images/urbanportal_horizontal.svg',
        'loginImageUrl': 'https://storage.googleapis.com/parkingbnb-html-images/login_bg.svg',
        'mainColor': '#AC8738',
        'lightColor': '#C79E46',
        'privacyPolicyUrl': `/#/privacy-policy`,
        'tncUrl': `/#/terms-and-conditions`,
    },
}

module.exports = whitelabelConfig
