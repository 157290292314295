<template>
  <div class="guest-summary occ-chart">
    <div class="summary-header d-flex align-center">
      <h3 class="main-title mr-auto">
        {{ $t("occ_dynamics") }}
      </h3>
      <v-btn text class="main-btn px-4 py-2 mr-6" @click="showDatepicker">{{
        $t("download")
      }}</v-btn>
    </div>
    <div class="position-relative">
      <div>
        <v-skeleton-loader :loading="true" min-height="365px" type="image" v-if="!show" />
        <img
          v-if="!show"
          class="placeholder-graph"
          src="@/assets/images/areachart-skeleton.png"
        />
        <apexchart
          :key="chartKey"
          v-dragged="onDragged"
          v-if="show"
          type="area"
          height="365"
          ref="chart"
          :options="chartOptions"
          :series="series"
        />
        <img
          src="@/assets/images/back.svg"
          alt="back"
          class="chart-back"
          v-if="show && getBack"
          @click="resetZoom"
        />
      </div>
    </div>
    <v-tabs v-model="selection" class="text-center" @change="updView($event)">
      <v-tab class="px-0" v-for="item in items" :key="item">{{ item }}</v-tab>
    </v-tabs>

    <Datepicker ref="datePicker" @save="getReport" />
  </div>
</template>

<script>
Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i][0] === a[j][0]) a.splice(j--, 1);
    }
  }

  return a;
};
import qs from "qs";
import { base } from "@/axios-api";
import XLSX from "xlsx";

const mainColor = require('~/whitelabel.config')[process.env.VUE_APP_BRAND].mainColor

export default {
  name: "OccupancyDynamics",
  props: {
    place: {
      default: 0,
    },
  },
  data() {
    return {
      chartKey: 0,
      getBack: false,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          id: "area-datetime",
          type: "area",
          height: 365,
          zoom: {
            autoScaleYaxis: false,
            enabled: false,
          },
        },
        colors: [mainColor],
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          range: 86400000,
          labels: {
            show: true,
            datetimeUTC: false,
            min: undefined,
            max: new Date().getTime(),
            style: {
              fontFamily: "Quicksand, sans-serif",
            },
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: "Quicksand, sans-serif",
            },
          },
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            let dateLabel = w.config.series[0].data[dataPointIndex][0];
            let toDisplayDate = this.$moment(dateLabel).format("DD MMM YYYY, HH:mm");
            this.return = { series, seriesIndex };
            return (
              '<div class="arrow_box px-4 py-2">' +
              "<span>" +
              toDisplayDate +
              "</span><h4 class='text-center'>" +
              series[seriesIndex][dataPointIndex] +
              "</h4></div>"
            );
          },
        },
        fill: {
          type: "gradient",
          colors: [mainColor],
          gradient: {
            opacityFrom: 0,
            opacityTo: 0,
            // shadeIntensity: 1,
            // opacityFrom: 0.3,
            // opacityTo: 0.9,
            // stops: [0, 100],
          },
        },
      },
      selection: 0,
      total: 0,
    };
  },
  components: {
    Datepicker: () => import("@/components/core/Datepicker"),
  },
  computed: {
    mainColor() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].mainColor;
    },
    isCyber() {
      return this.$store.getters["app/isCyber"];
    },
    baseURL() {
      return this.isCyber ? "dashboard_cyber" : "dashboard";
    },
    show() {
      return this.$store.state.app.tab === "dashboard" && this.series[0].data.length > 1;
    },
    items() {
      return [
        this.$t("day"),
        this.$t("week"),
        this.$t("month"),
        this.$t("half_year"),
        this.$t("year"),
      ];
    },
    stateData() {
      return this.$store.state.dashboard.occ_dynamics;
    },
    places() {
      return this.$store.state.app.places;
    },
    allSpaces() {
      let count = 0;
      if (this.place) {
        count = this.places.filter((i) => i.place_id === this.place)[0].place_count;
      } else {
        this.places.map((place) => {
          count = count + place.place_count;
        });
      }
      return count;
    },
    dynamics() {
      let data = [];
      switch (this.selection) {
        case 0:
          data = this.stateData.day.filter((p) => p.place_id === this.place)[0]
            ? this.stateData.day.filter((p) => p.place_id === this.place)[0].occupancies
            : [];
          break;
        case 1:
          data = this.stateData.week.filter((p) => p.place_id === this.place)[0]
            ? this.stateData.week.filter((p) => p.place_id === this.place)[0].occupancies
            : [];
          break;
        case 2:
          data = this.stateData.month.filter((p) => p.place_id === this.place)[0]
            ? this.stateData.month
                .filter((p) => p.place_id === this.place)[0]
                .occupancies.filter((el, i) => i % 6 === 0)
            : [];
          break;
        case 3:
          data = this.stateData.half_year.filter((p) => p.place_id === this.place)[0]
            ? this.stateData.half_year.filter((p) => p.place_id === this.place)[0]
                .occupancies
            : [];
          break;
        case 4:
          data = this.stateData.year.filter((p) => p.place_id === this.place)[0]
            ? this.stateData.year
                .filter((p) => p.place_id === this.place)[0]
                .occupancies.filter((el, i) => i % 2 === 0)
            : [];
          break;
        default:
          break;
      }
      return data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    },
    series() {
      let series = [
        {
          data: [],
          name: this.$t("occupancy"),
        },
      ];
      let dynamics = [...this.dynamics] || [];
      if (dynamics.length) {
        dynamics.map((record) => {
          series[0].data.push([new Date(record.date).getTime(), record.occupancy]);
        });
      }
      return series;
    },
    statePlaces() {
      return [...this.$store.state.app.places];
    },
    m() {
      return this.$moment;
    },
    dates() {
      let today = this.roundToNearestHour(this.m().subtract(1, "minutes")).toISOString();

      let months1ago = this.roundToNearestHour(
        this.m().subtract(1, "months")
      ).toISOString();
      let months2ago = this.roundToNearestHour(
        this.m().subtract(2, "months")
      ).toISOString();
      let months6ago = this.roundToNearestHour(
        this.m().subtract(6, "months")
      ).toISOString();

      let year1ago = this.roundToNearestHour(this.m().subtract(1, "years")).toISOString();
      return { today, months1ago, months2ago, months6ago, year1ago };
    },
  },
  watch: {
    total(newValue) {
      if (newValue > 200) {
        this.getBack = true;
      } else if (newValue < 200) {
        this.getBack = false;
      }
    },
  },
  methods: {
    roundToNearestHour(date) {
      date = new Date(date.toISOString());
      date.setMinutes(date.getMinutes() + 30);
      date.setMinutes(0, 0, 0);

      return date;
    },
    updView(timeline) {
      this.getData(timeline);
      this.selection = timeline;
      this.chartOptions.xaxis.range = this.getRange();
      // Принудительный ререндер графика
      this.chartKey++;
      this.total = 0;
    },
    getData(type) {
      let place_id = this.statePlaces.map((p) => p.place_id);
      switch (type) {
        case 1:
          this.$store.dispatch("dashboard/getOccupancyDynamics", {
            params: {
              from: this.m().subtract(1, "weeks").toISOString(),
              to: this.m(this.dates.today)
                .startOf("hour")
                .subtract(1, "minutes")
                .toISOString(),
              group_by: "hour",
              place_id,
            },
            type: "week",
          });
          break;
        case 2:
          this.$store.dispatch("dashboard/getOccupancyDynamics", {
            params: {
              from: this.dates.months1ago,
              to: this.m(this.dates.today)
                .startOf("hour")
                .subtract(1, "minutes")
                .toISOString(),
              group_by: "hour",
              place_id,
            },
            type: "month",
          });
          break;
        case 3:
          this.$store.dispatch("dashboard/getOccupancyDynamics", {
            params: {
              from: this.dates.months6ago,
              to: this.dates.today,
              group_by: "day",
              place_id,
            },
            type: "half_year",
          });
          break;
        case 4:
          this.$store.dispatch("dashboard/getOccupancyDynamics", {
            params: {
              from: this.dates.year1ago,
              to: this.dates.today,
              group_by: "day",
              place_id,
            },
            type: "year",
          });
          break;
      }
    },
    getRange() {
      switch (this.selection) {
        case 0:
          return 86400000;
        case 1:
          return 86400000 * 7;
        case 2:
          return 86400000 * 31;
        case 3:
          return 86400000 * 31 * 6;
        case 4:
          return 86400000 * 31 * 12;
      }
    },
    onDragged({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      if (deltaX) {
        this.total = this.total + deltaX;
      }
      if (last) {
        let max = new Date().getTime() - (this.total * this.getRange()) / 480;
        if (max >= this.series[0].data[this.series[0].data.length - 1][0]) {
          this.resetZoom();
        } else {
          this.$refs.chart.updateOptions({
            xaxis: {
              max,
            },
          });
        }

        if (this.series[0].data[0][0] > max - this.getRange()) {
          this.getNewParams();
        }
      }
      return {
        el,
        deltaX,
        deltaY,
        offsetX,
        offsetY,
        clientX,
        clientY,
        first,
        last,
      };
    },

    resetZoom() {
      this.$refs.chart.updateOptions({
        xaxis: {
          max: this.series[0].data[this.series[0].data.length - 1][0],
        },
      });
      this.total = 0;
    },

    getNewParams() {
      let params = {};
      let curMinDate = this.$moment(this.series[0].data[0][0]).subtract(1, "minutes");
      let curMinDateHour = curMinDate.startOf("hour");

      params.to = curMinDate.toISOString();
      switch (this.selection) {
        case 0:
          params.group_by = "moment";
          params.from = curMinDate.subtract(4, "days").toISOString();
          break;
        case 1:
          params.group_by = "hour";
          params.from = curMinDate.subtract(1, "weeks").toISOString();
          params.to = curMinDateHour.toISOString();
          break;
        case 2:
          params.group_by = "hour";
          params.from = curMinDate.subtract(1, "months").toISOString();
          params.to = curMinDateHour.toISOString();
          break;
        case 3:
          params.group_by = "day";
          params.from = curMinDate.subtract(6, "months").toISOString();
          break;
        case 4:
          params.group_by = "day";
          params.from = curMinDate.subtract(1, "years").toISOString();
          break;
        default:
          break;
      }
      this.updateSeries({ ...params });
      return params;
    },

    getAllIndexes(arr, val) {
      let indexes = [];
      for (let j = 0; j < arr.length; j++) {
        if (arr[j][0] === val[0]) {
          indexes.push(j);
        }
      }
      return indexes;
    },

    updateSeries(params) {
      let place_id = this.statePlaces.map((p) => p.place_id);

      base({
        url: `/${this.baseURL}/occupancy`,
        params: { ...params, place_id },
        method: "GET",
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      }).then((res) => {
        if (res.status === 200) {
          let dynamics = res.data.body || [];
          dynamics = dynamics.filter((d) => d.place_id === this.place)[0].occupancies;

          let newSeries = dynamics.map((r) => [
            this.$moment(r.date).valueOf(),
            r.occupancy,
          ]);
          newSeries = newSeries.concat([...this.series[0].data]);

          newSeries = newSeries
            .filter((a) => this.getAllIndexes(newSeries, a).length === 1)
            .sort((a, b) => a[0] - b[0]);

          this.$refs.chart.updateSeries([
            {
              data: newSeries,
            },
          ]);
          this.series[0].data = newSeries;

          let max = new Date().getTime() - (this.total * this.getRange()) / 480;
          this.$refs.chart.updateOptions({
            xaxis: {
              max,
            },
          });
        }
      });
    },
    showDatepicker() {
      this.$refs.datePicker.show();
    },
    getReport(dates) {
      this.$store.commit("app/set_downloader", true);
      let createXLSLFormatObj = [];
      let xlsHeader = [this.$t("occupancy"), this.$t("dates")];
      let xlsRows = [];
      createXLSLFormatObj.push(xlsHeader);
      let curPlace =
        this.statePlaces.filter((i) => i.place_id === this.place)[0] ||
        this.statePlaces[0];
      let placeName = curPlace.place_name;
      let datesName = `${this.$moment(dates[0]).format("DD.MM.YYYY")} - ${this.$moment(
        dates[1]
      ).format("DD.MM.YYYY")}`;
      let filename = `${placeName} ${this.$t("occ_dynamics")} ${datesName}.xlsx`;
      var ws_name = this.$t("occ_dynamics");

      base({
        url: `/${this.baseURL}/occupancy`,
        params: {
          group_by: "moment",
          from: this.$moment(dates[0]).toISOString(),
          to: this.$moment(dates[1]).toISOString(),
          place_id: this.statePlaces.map((p) => p.place_id),
        },
        method: "GET",
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      }).then((res) => {
        let dynamics = res.data.body || [];
        xlsRows = dynamics.filter((d) => d.place_id === this.place)[0].occupancies;

        xlsRows.map((row) => {
          let innerData = [
            row.occupancy,
            this.$moment(row.date).format("DD.MM.YYYY HH:mm"),
          ];
          createXLSLFormatObj.push(innerData);
        });

        let wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /* Write workbook and Download */
        XLSX.writeFile(wb, filename);
        this.$store.commit("app/set_downloader", false);
      });
    },
  },
};
</script>

<style lang="scss">
.occ-chart {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  .v-tabs .v-icon {
    display: none !important;
  }
  .v-tab {
    width: 100%;
    min-width: 0;
    padding: 0 !important;
    font-size: 14px !important;
  }
  .v-skeleton-loader__image {
    min-height: 305px;
    margin-top: 24px;
  }
  .position-relative {
    position: relative;
    .placeholder-graph {
      display: block;
      width: 100%;
      height: 83%;
      position: absolute;
      opacity: 0.6;
      top: 24px;
      left: 0;
      right: 0;
    }
  }
  .chart-back {
    cursor: pointer;
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    bottom: 15%;
    right: 3%;
  }
}
</style>
