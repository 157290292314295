<template>
  <div class="login-form">
    <v-container fluid>
      <h3 class="login-title">{{ $t("login_to_platform", { platform: brand }) }}</h3>
      <v-form class="login-form__content" ref="form">
        <v-text-field
          class="login-inp"
          v-model="username"
          :rules="[() => !!username || $t('incorrect_username')]"
          :label="$t('username')"
          ref="input"
          aria-autocomplete="off"
        />
        <v-text-field
          @keyup.enter="login"
          class="login-inp"
          v-model="password"
          :rules="[() => !!password || $t('incorrect_password')]"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('password')"
        >
          <template v-slot:append>
            <img
              style="cursor: pointer"
              alt="eye"
              @click="showPassword = !showPassword"
              :src="
                showPassword
                  ? 'https://storage.googleapis.com/parkingbnb-html-images/ Eye open.svg'
                  : 'https://storage.googleapis.com/parkingbnb-html-images/ Eye closed.svg'
              " /></template
        ></v-text-field>
        <v-card-actions class="error-message mb-3 mt-n3">
          {{ error }}
        </v-card-actions>
        <v-btn min-width="190px" class="main-btn login" rounded @click="login">{{
          $t("login")
        }}</v-btn>
      </v-form>

      <v-footer class="pa-0 d-flex justify-space-around">
        <div>&copy; {{ new Date().getFullYear() }} UrbanChain</div>
        <v-btn
          class="footer-btn"
          :href="tncUrl"
          target="_blank"
          text
          >{{ $t("tnc") }}</v-btn
        >
        <v-btn
          class="footer-btn"
          :href="privacyPolicyUrl"
          target="_blank"
          text
          >{{ $t("privacy_policy") }}</v-btn
        >
      </v-footer>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
    };
  },
  computed: {
    error() {
      return this.$store.state.app.errorLogin;
    },
    brand() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].brand;
    },
    privacyPolicyUrl() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].privacyPolicyUrl;
    },
    tncUrl() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].tncUrl;
    },
  },
  methods: {
    login() {
      let username = this.username;
      let password = this.password;
      this.$store.dispatch("app/login", { username, password });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.$el.focus();
    });
  },
};
</script>
