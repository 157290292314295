<template>
  <div class="tnc_body">
    <h1>Terms and Conditions</h1>
    <p> These terms and conditions, which may be amended with no prior notice, apply to all services UrbanChain Group Limited directly or indirectly (through distributors) renders. By accessing, browsing and using our website or any of our applications through whatever platform (hereafter collectively referred to as the “website”) and/or by utilizing services provided by UrbanChain Group Limited, you acknowledge and agree to have read, understood and agreed to the terms and conditions set out below (including the privacy statement). </p>
    <p> These pages, the content and infrastructure of these pages, and the service provided on these pages and through the website or mobile applications (the “{{ brand }}”) are owned, operated and provided by UrbanChain Group Limited (“{{ brand }}”, “us”, “we” or “our”) and are provided for you subjected to the terms and conditions set out below. </p>
    <h2>1. Scope of our Service</h2>
    <p> The Services (“Services”) provided by UrbanChain Group Limited through this website or {{ brand }} mobile application are limited to: (i) listing locations and vacancy data of certain parking spots and car parking objects (i.e. any off-street and on-street parking located within parking lots, parking complexes, hotels, motels, business centres, commercial and non-commercial organisations, airports, parking owned by individuals, educational entities and other properties that has parking facilities, collectively the “parking” and “rentals”); (ii) suggesting the “best” parking object based on the object’s ranking within {{ brand }}; (iii) providing access to the parking objects that have {{ brand }} hardware products installed; (iv) collecting fees on behalf of the independent owners, lessors, managers or operators of the parking objects ( the “Parking Services Provider” or “Provider”) that have {{ brand }} hardware products installed. UrbanChain Group Limited does not provide services other than mentioned above. </p>
    <p> You agree to comply with all applicable laws when accessing or using our Services, and you may only access or use the Services for lawful purposes. </p>
    <p> By accessing a parking object found using {{ brand }} mobile application, you enter into a direct (legally binding) contractual relationship with the Parking Services Provider and agree in all cases to abide by the Provider’s Terms and Conditions, including but not limited to Providers which use UrbanChain Group’s software and/or hardware to facilitate parking management services. UrbanChain Group Limited acts solely as an intermediary between you and the Provider, transmitting the details of your account within {{ brand }} and the payment to the Provider and sending you a confirmation email and/or push notification for and on behalf of the Provider. When UrbanChain Group Limited receives your payment, your obligation to pay the Provider for the parking services is fully satisfied. UrbanChain Group Limited makes reasonable efforts to ensure the information regarding parking objects’ amenities and vacancy data is true and accurate. UrbanChain Group Limited shall not be hold liable in case any of the mentioned information is not correct. </p>
    <p> Our services are made available for personal and non-commercial use only. Therefore, you are not allowed to re-sell, deep-link, use, copy, monitor (e.g. spider, scrape), display, download or reproduce any content or information, software, products or services available on our website for any commercial or competitive activity or purpose. </p>
    <h2>2. Privacy and Cookies</h2>
    <p> By visiting or using this website and {{ brand }} mobile application, you consent to our collection and use of personal information as discussed in our Privacy Policy, which is hereby incorporated into this Agreement. A copy of the Privacy Policy can be accessed at
      <a :href="privacyPolicyUrl" class="">{{ privacyPolicyUrl }}</a>. </p>
    <h2>3. User Account</h2>
    <p> In order to use the {{ brand }} mobile application you need create a user account and login (a “User Account”). You agree that all information you provide to us in connection with creating your User Account or otherwise is current, accurate and complete. You are solely responsible for all activity that occurs with respect to your User Account. You are solely responsible for maintaining the confidentiality of your User Account and keeping the username and password to your User Account secure. You agree to notify us immediately of any security breach or unauthorized use of your User Account. We will not be liable for any costs, losses, claims or damages that you or any third party incur which are directly or indirectly caused by any unauthorized use of your User Account. You agree to never use another party’s User Account without such party’s express written authorization. </p>
    <p> By registering a User Account, you agree, confirm and accept that: (i) you are using your genuine identity; (ii) the payment methods registered by you for the Services are genuine and belong to you personally; and (iii) we may send push notifications or messages to you based on your phone number or email address for verification and registration purposes and in connection with your use of the Services from time to time. </p>
    <h2>4. User Wallet, Balance and Balance Withdrawal</h2>
    <p> After creation, every User Account gets an electronic wallet (a “Wallet”) attached to it. A Wallet is a type of pre-paid account in which a user stores funds for future transaction. In order to be able to pay for the parking services, you need to have sufficient amount of funds kept in your Wallet. You may transfer funds to your Wallet from either your credit card, PayMe account, WeChat account or Alipay account. In order to use either of the aforementioned methods, we will require you to provide relevant details of such payment method. You may keep such payment methods as a default method of adding funds to your Wallet or delete it after transferring desired amount to your Wallet. </p>
    <p> Balance means the amount of funds in your Wallet that are available for parking services payments. In case your balance falls below HKD 100, you get a reminding email and push notification suggesting you to add funds to your wallet. Note that you cannot access the parking object if your Balance is equal or less HKD 0. Furthermore, the Parking Services Provider has the right to not let you exit the parking object in case your Balance after paying for parking services provided falls below HKD negative 88. </p>
    <p> Funds, kept at your Balance, can be transferred back to your credit card, PayMe account, WeChat account or Alipay account registered in your User Account. Your receiving bank will credit the money to such account or credit card in accordance with your receiving bank’s applicable practice. We have no control over the process, and we are not responsible for any delay or failure by your receiving bank in crediting the money to such account or credit card. </p>
    <p> Once your request for withdrawal is received and inspected, we will send you a notification email. Please let us 15 working days for processing the withdrawal operation. Once processed, your funds will automatically be credited to your credit card, PayMe account, WeChat account or Alipay account. </p>
    <p> Note that the Balance withdrawal incurs transaction fees of 5% of the transaction amount, but not less than HKD 10. </p>
    <p> Withdrawal is impossible if the User had deleted his User Account prior to Balance withdrawal initiation. </p>
    <h2>5. Parking Advisor Function</h2>
    <p> After destination input, {{ brand }} mobile application shows all parking objects in the area, naming four of them as Cheapest, Closest, Fastest and Best. </p>
    <p> Cheapest means that the named parking object charges the lowest fee for hourly parking service at this point of time or for the period of stay you indicated. </p>
    <p> Closest means that the named parking object located closer to your final destination than any other parking object in the area. We name a parking object as Fastest if the combined transportation time to your destination, including both the driving time to the parking object and the walking time from the parking object to the destination is shorter compared to any other parking object in the area. </p>
    <p> We name a parking object as Best and make it a default selection if, according to our calculations based on multiple criteria, such parking object has the highest ranking among other parking objects in the area, is relatively cheap and located relatively close to your destination point. </p>
    <p> All prices on the {{ brand }} mobile application or website are either per hour on default or per your chosen period for your entire stay and are displayed including all relevant taxes (subject to change of such taxes), unless stated differently on our mobile application or website or in the confirmation email. Obvious errors and mistakes (including misprints) are not binding. Note that our service comes at no additional charge for the end-users. </p>
    <h2>6. Further Correspondence</h2>
    <p> By accepting Services {{ brand }} mobile application offers, you agree to receive an email and/or push notification which we may send to you promptly after your stay inviting you to complete our review form. Please see our privacy and cookies policy for more information about how we may contact you. </p>
    <h2>7. Ranking and Reviews</h2>
    <p> The completed parking visitor review may be wholly or partly used and placed by {{ brand }} at its sole discretion (e.g. for marketing, promotion or improvement of our service) in {{ brand }} mobile application, our website or social media platforms, newsletters, special promotions or other channels owned, hosted, used or controlled by {{ brand }}. We reserve the right to adjust, refuse or remove reviews at our sole discretion. The guest review form should be regarded as a survey and does not include any (further commercial) offers, invitations or incentives whatsoever. </p>
    <h2>8. Disclaimer</h2>
    <p> Subject to the limitations set out in these terms and conditions and to the extent permitted by law, we shall only be liable for direct damages actually suffered, paid or incurred by you due to an attributable shortcoming of our obligations in respect to our Services, up to an aggregate amount of the aggregate cost of parking services rendered by the Provider as set out in the confirmation email and/or push notification. </p>
    <p> However and to the extent permitted by law, neither we nor any of our officers, directors, employees, representatives, subsidiaries, affiliated companies, distributors, affiliate (distribution) partners, licensees, agents or others involved in creating, sponsoring, promoting, or otherwise making available the site and its contents shall be liable for (i) any punitive, special, indirect or consequential loss or damages, any loss of production, loss of profit, loss of revenue, loss of contract, loss of or damage to goodwill or reputation, loss of claim, (ii) any inaccuracy relating to the (descriptive) information (including rates, availability and ratings) of the accommodation as made available on our website or mobile application, (iii) the services rendered by the Provider or other business partners, (iv) any (direct, indirect, consequential or punitive) damages, losses or costs suffered, incurred or paid by you, pursuant to, arising out of or in connection with the use, inability to use or delay of our website/mobile application, or (v) any (personal) injury, death, property damage, or other (direct, indirect, special, consequential or punitive) damages, losses or costs suffered, incurred or paid by you, whether due to (legal) acts, errors, breaches, (gross) negligence, wilful misconduct, omissions, non-performance, misrepresentations, tort or strict liability by or (wholly or partly) attributable to the accommodation or any of our other business partners (including any of their employees, directors, officers, agents, representatives or affiliated companies) whose products or service are (directly or indirectly) made available, offered or promoted on or through {{ brand }} mobile application, including any (partial) cancellation, overbooking, strike, force majeure or any other event beyond our control. </p>
    <p> You agree and acknowledge that the Provider is at all times responsible for the collection, withholding, remittance and payment of the applicable taxes due on the total amount of the room price to the relevant tax authorities. {{ brand }} is not liable or responsible for the remittance, collection, withholding or payment of the relevant taxes due on the parking price to the relevant tax authorities. </p>
    <p> {{ brand }} mobile application may contain links to third party websites. The linked sites are not under our control and we are not responsible for the contents of any linked website, nor do we make any representation or warranties with regard to any other website that you may access through the {{ brand }} mobile application. </p>
    <h2>9. Termination</h2>
    <p> We reserve the right to suspend or terminate your User Account in the event you violate any provision of these Terms and Conditions or if you use Services in a fraudulent manner. </p>
    <h2>10. Intellectual property rights</h2>
    <p> The text, images, data, illustrations, files, audio and video clips, designs, documents and other materials and content (collectively, the “Content”) on this website and {{ brand }} mobile application is the property of UrbanChain Group Limited and may be protected by copyright and other restrictions. Copyrights and other proprietary rights in the Content may also be owned by parties other than us. You may not copy, modify, distribute or otherwise use any of the Content, except for your personal and non-commercial use. Please note that we may withdraw any Content from this website and {{ brand }} mobile application at any time in our sole discretion. Unauthorized commercial publication or exploitation of text, images, documents, materials or any other Content is prohibited without our express written consent. If you wish to use any of the Content for commercial use, publication, or any purpose other than personal use, you must obtain our express written permission prior to such use. </p>
    <h2>11. Miscellaneous</h2>
    <p> To the extent permitted by law, these terms and conditions and the provision of our services shall be governed by and construed in accordance with law and any dispute arising out of these general terms and conditions and our services shall exclusively be submitted to the competent courts in our country. </p>
    <p> The original English version of these terms and conditions may have been translated into other languages. The translated version is a courtesy and office translation only and you cannot derive any rights from the translated version. In the event of a dispute about the contents or interpretation of these terms and conditions or inconsistency or discrepancy between the English version and any other language version of these terms and conditions, the English language version to the extent permitted by law shall apply, prevail and be conclusive. The English version is available on our website/mobile application or shall be sent to you upon your written request. </p>
    <p> If any provision of these terms and conditions is or becomes invalid, unenforceable or non-binding, you shall remain bound by all other provisions hereof. In such event, such invalid provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and you will at least agree to accept a similar effect as the invalid, unenforceable or non-binding provision, given the contents and purpose of these terms and conditions. </p>
    <p> The original UK English version of these terms and conditions may have been translated into other languages. The translated version is a courtesy and office translation only and you cannot derive any rights from the translated version. In the event of a dispute about the contents or interpretation of these terms and conditions or inconsistency or discrepancy between the UK English version and any other language version of these terms and conditions, the UK English language version to the extent permitted by law shall apply, prevail and be conclusive. The UK English version is available on our website/mobile application or shall be sent to you upon your written request. </p>
    <p> If any provision of these terms and conditions is or becomes invalid, unenforceable or non-binding, you shall remain bound by all other provisions hereof. In such event, such invalid provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and you will at least agree to accept a similar effect as the invalid, unenforceable or non-binding provision, given the contents and purpose of these terms and conditions. </p>
    <h2>12. About {{ brand }} and the support companies</h2>
    <p> The online reservation/contactless payment service is rendered by {{ brand }}, which belongs to UrbanChain Group Limited, a private limited liability company, incorporated under laws of Hong Kong. </p>
    <p> {{ brand }} is supported by various affiliated group companies (the “support companies”) throughout the world. The support companies only provide an internal supporting role to and for the benefit of {{ brand }}. Certain designated support companies render limited customer care support services (only by telephone). The support companies do not have any website (and do not in any way control, manage, maintain or host the website). The support companies do not have any power or authority to render the service, to represent {{ brand }} or to enter into any contract in the name of, for or on behalf of {{ brand }}. You do not have a (legal or contractual) relationship with the support companies. The support companies do not operate and are not authorised to act as any form of process or service agent of {{ brand }}. {{ brand }} does not accept nor assume any domicile at any place, location or office in the world (also not at the office of its support companies), other than its registered office. </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  computed: {
    brand() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].brand;
    },
    privacyPolicyUrl() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].privacyPolicyUrl;
    },
  },
}
</script>

<style scoped lang="scss">
.tnc_body {
  margin: 30px;
  min-width: 320px;
}
</style>
