<template>
  <div class="tnc_body">
    <h1>Privacy Policy</h1>
    <h2>Introduction</h2>
    <p> This Policy of UrbanChain Group Limited including its affiliates (further ‘the Company’ or simply ‘we’, ‘our’, ‘us’) is prepared to help you understand how we collect, use, and protect your information. We respect your privacy and comply with applicable data protection and privacy laws. We intend to support you with making informed decisions. </p>
    <h2>Definitions</h2>
    <p>
      <ul>
        <li> As used in this Policy, ‘Affiliates’ mean any person or entity which directly or indirectly controls, is controlled by or is under common control with UrbanChain Group Limited, whether by ownership or otherwise.</li>
        <li> Anonymous, statistical, or aggregated information, on a de-identified basis (such as anonymous location information, enrollment numbers, demographic group information, etc.), in a form that does not enable the identification of a specific user is referred to as ‘Anonymous Data’.</li>
        <li> Our smart parking software platform and mobile application for searching, filtering, scheduling, starting, and completing parking sessions and transactions, including but not limited to on-street and off-street parking, residential permits, parking reservations, long-term or short-term parking, etc., and other applications that we may develop are collectively referred to as ‘{{ brand }}’.</li>
        <li> ‘Cookies’ mean the small data files on your computer or other device which consist of cookies, pixel tags, e-tags, ‘flash cookies’, or other local storage provided by your browser or associated applications.</li>
      </ul>
    </p>
    <h2>Scope</h2>
    <p> When you register an account and use {{ brand }}, you are directed to this Policy and required to accept and/or agree to this Policy in order to use the services. Each time you use {{ brand }} and each time you provide us with information or data via the application or otherwise, you are accepting and agreeing to the current version of this Policy and consent and agree to our collection and processing of your information as set forth in this Policy. </p>
    <p> UrbanChain Group Limited may amend, update, modify, or revise this Policy at any time by posting an amended, updated, modified, or revised version of the Policy on our Website. The revised version will be effective as of the published effective date. Your continued use of {{ brand }} after such effective date indicates your acceptance of and agreement to the amended, updated, modified, or revised Policy. You should check our Policy periodically for modifications. </p>
    <p> This Policy does not apply to any website or application owned and/or operated by or on behalf of any third party, even if we provide a link to such website/application in {{ brand }}. </p>
    <p> Note that this Policy contains several important exceptions. Please read the entire Policy to be sure that you understand these exceptions. </p>
    <h2>How We Collect Information and What We Collect</h2>
    <p> When you register an account in {{ brand }}, whether by downloading and installing {{ brand }} mobile application or creating an account through other sources directly related to us, we collect Personal Information consisting of, at a minimum, your name, email address, mobile phone number, vehicle type and plate, payment methods, Username and password. </p>
    <p> Over the course of your Use of {{ brand }}, we may collect additional Personal Information such as: your mailing and billing addresses, data of transactions, GPS data, information that you voluntarily provide like User Content; information received from your debit/credit card provider, digital wallet, or financial institution; information necessary to address your help requests or to troubleshoot problems; information contained in any communication you have with us; and your Username and password. </p>
    <p> We use Cookies and other tools (such as log files, clickstream data, and referrers) to collect information about your use of {{ brand }} such as: IP address and information about the device you use to access {{ brand }}, such as your Media Access Control (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and model, language, Internet browser type and version, the version of {{ brand }} application you are using, traffic data, etc. </p>
    <h2>Use of Cookies</h2>
    <p> We use Cookies for various purposes, such as to save you the need to re-enter your Username and password each time you log-in, to facilitate the use of our website and mobile application, to collect statistical information, to verify information, and to customize our website to your personal preferences and for information security purposes. Some Cookies may expire when the session ends and you exit your browser while other Cookies are saved on your device or computer. To learn out more about Cookies, go to
      <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>. </p>
    <h2>Opting Out</h2>
    <p>
      <ul>
        <li> At any time, you can edit or remove your Personal Information as described herein, however, we may retain your Personal Information for archival purposes or when we required to retain such Personal Information pursuant to other agreements or by law and we may retain, use, and share your Anonymous Data that we collected prior to when you opt-out.</li>
        <li> At any time, you may delete your account and uninstall {{ brand }} mobile application. We will use commercially reasonable efforts to remove your Personal Information from our files, however, we may retain your Personal Information for archival purposes or when we are required to retain such Personal Information pursuant to other agreements or by law and we may retain, use, and share your Anonymous Data that we previously collected prior to your deletion of your account.</li>
        <li> If you do not want us to use Personal Information as part of the pool of Anonymous Data that we share, you may choose to opt-out. Please contact us with your request using Contacts section below. We may retain, use, and share your Anonymous Data that we collected prior to when you-opt out.</li>
        <li> If you do not want to receive SMS texts or email, you can change your preferences in your account permissions on your Personal Pages, via the settings in {{ brand }} mobile application or by directly contacting us using contact details below. Additionally, in the case of emails, you may click on the “Unsubscribe” link in the email. If you do not wish to receive push notifications, you can change the notifications setting on your mobile device.</li>
        <li> If you wish to block the {{ brand }}’s use of Cookies, then please follow the necessary instructions of your browser or go to www.allaboutcookies.org to learn how to restrict or block Cookies. Please note that disabling Cookies may complicate, limit, or even prevent your use of {{ brand }} and its features and functionalities.</li>
        <li> If you wish to block GPS location functionality, you can suspend GPS access to {{ brand }} mobile application using your mobile device settings.</li>
        <li> If you wish to block access to your connected social media accounts, you can do so by disabling or restricting connections via the settings in {{ brand }} mobile application or in your social media account.</li>
        <li> Your opt-out will not stop our use of Personal Information as part of the pool of Anonymous Data for other business purposes, such as:
          <ul>
            <li>Processing Transactions;</li>
            <li>Risk and fraud prevention;</li>
            <li>Maintenance and development of {{ brand }};</li>
            <li>Reporting on the state of our business;</li>
            <li> Designing and operating our other products and services;</li>
            <li> Providing Payment Information to Transaction Entities.</li>
          </ul>
        </li>
        <li> Your opt-out will not affect any of your preferences or the choices provided to you by Transaction Entities. Please contact the Transaction Entity if you want to change your preferences or choices regarding its use of your information.</li>
        <li> You will not be able to opt out of in-app messaging in as much as it is used, without limitation, to provide notices about {{ brand }}.</li>
        <li> For any additional information on opting out, please contact us with contact details in below section.</li>
      </ul>
    </p>
    <h2>International users</h2>
    <p> If you live outside Hong Kong and are using {{ brand }}, your Personal Information may be processed or stored on servers located in Hong Kong. By using {{ brand }}, you authorize the export and processing of your Personal Information to Hong Kong. </p>
    <h2>Law and Jurisdiction</h2>
    <p> This Policy and our legal obligations are subject to the laws of Hong Kong SAR that comply with the Personal Data (Privacy) Ordinance, Chapter 486 of the laws of the Hong Kong, excluding its principles of conflicts of law and the private international law rules. Regardless of your location, the exclusive jurisdiction and the venue for any litigation will be in the state or federal courts of Hong Kong. </p>
    <h2>Your rights</h2>
    <p> If you are a data subject under the General Data Protection Regulation (‘GDPR’) you have a number of important rights, including rights to: </p>
    <p>
      <ul>
        <li> access to your personal data and to certain other supplementary information that this Policy is already designed to address;</li>
        <li> require Us to correct any incomplete or inaccurate personal data which We hold;</li>
        <li> require the erasure of personal data concerning you in certain situations. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request;</li>
        <li> receive the personal data concerning you which you have provided to Us, in a structured, commonly used and machine-readable format and have the right to transmit those data to a third party in certain situations;</li>
        <li> object at any time to processing of personal data concerning you for direct marketing through following the unsubscribe link contained in our marketing emails;</li>
        <li> object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you;</li>
        <li> object in certain other situations to our continued processing of your personal data;</li>
        <li> otherwise restrict our processing of your personal data in certain circumstances;</li>
        <li> withdraw consent at any time in respect of personal data being processed by us on the basis of us having your consent to do so;</li>
        <li> claim compensation for damages caused by our breach of any data protection laws.</li>
      </ul>
    </p>
    <p> For further information on each of those rights, including the circumstances in which they apply, see the Guidance from the official documents of the General Data Protection Regulation. </p>
    <p> If you would like to exercise any of those rights, please contact us using our Contacts below. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.If you would like to exercise any of those rights, please contact us using our Contacts below. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.If you would like to exercise any of those rights, please contact us using our Contacts below. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated. </p>
    <h2>Contacts</h2>
    <p> Our full contact details are: </p>
    <p> Full name of legal entity: UrbanChain Group Limited. Postal address: Unit 1603, 16th Floor, The L. Plaza, 367-375 Queen’s Road Central, Sheung Wan, Hong Kong. </p>
    <p> All questions, comments and requests regarding this Privacy Policy should be addressed to <a href="mailto:info@urbanchain.world">info@urbanchain.world</a>. </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  computed: {
    brand() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].brand;
    },
  },
}
</script>

<style scoped lang="scss">
.tnc_body {
  margin: 30px;
  min-width: 320px;
}
</style>
