import axios from 'axios'
import store from '@/store/index'


// const BASE_HOST =  "https://demoapi.corporate.parkingbnb.world"
// const BASE_HOST = "http://18.167.4.191:6003" //баптист прод
// const BASE_HOST = "http://192.168.1.111:8080" //баптист локалка
// const BASE_HOST = "http://18.167.4.191:6378" //Misha local debugger
// const BASE_HOST = "http://127.0.0.1:8880" //Misha local debugger


//TEST ENV
// const BASE_HOST = "https://apitest.corporate.parkingbnb.world"
// const SFSG_HOST = "https://sfsg.parkingbnb.world/test/api"
// const CYBER_HOST = "https://cyberport.parkingbnb.world/api/test"
// const MONTHLY_HOST = "https://monthly.parkingbnb.world/trade-and-industry-tower-carpark-apitest"
// const CHAT_HOST = "https://chat.parkingbnb.world/test/chat"
// const COUNTER_HOST = "https://api.parkingbnb.world/tv"

// PROD ENV
// const BASE_HOST = "https://api.corporate.parkingbnb.world"
// const SFSG_HOST = "https://sfsg.parkingbnb.world/api"
// const CYBER_HOST = "https://cyberport.parkingbnb.world/api/"
// const MONTHLY_HOST = "https://monthly.parkingbnb.world/trade-and-industry-tower-carpark-api"
// const CHAT_HOST = "https://chat.parkingbnb.world/chat"
// const COUNTER_HOST = "https://api.parkingbnb.world/tv"

const BASE_HOST = process.env.VUE_APP_BASE_HOST
const SFSG_HOST = process.env.VUE_APP_SFSG_HOST
const CYBER_HOST = process.env.VUE_APP_CYBER_HOST
const MONTHLY_HOST = process.env.VUE_APP_MONTHLY_HOST
const CHAT_HOST = process.env.VUE_APP_CHAT_HOST
const COUNTER_HOST = process.env.VUE_APP_COUNTER_HOST

const base = axios.create({
    baseURL: BASE_HOST,
})

base.interceptors.request.use((config) => {
    let token = localStorage.getItem("credentials")
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["App-Version"] = localStorage.getItem("version");
    config.headers["Content-Language"] = localStorage.getItem("language");
    return config;
});

base.interceptors.response.use((res) => {
    return res;
}, (err) => {
    if (err.response.status === 412) {
        let version = err.response.data
        localStorage.setItem("version", version)
        location.reload()
    } else if (err.response.status === 401) {
        store.dispatch("app/logout")
    }
    return Promise.reject(err)
});

const pwa = axios.create({
    baseURL: SFSG_HOST,
})

pwa.interceptors.request.use((config) => {
    let token = localStorage.getItem("pwa_token")
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Accept-Language"] = `en_us`;
    config.headers["App-Version"] = Number(localStorage.getItem("pwa_version"));
    return config;
});
pwa.interceptors.response.use((res) => {
    return res;
}, (err) => {
    console.log(err.response)
    if (err.response.status === 412) {
        let version = err.response.data
        localStorage.setItem("pwa_version", version)
        location.reload()
    } else if (err.response.status === 401) {
        store.dispatch("app/logout")
    }
    return Promise.reject(err)
});


const chat = axios.create({
    baseURL: CHAT_HOST,
})

chat.interceptors.request.use((config) => {
    let token = localStorage.getItem("chat_token")
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
});

const cyberport = axios.create({
    baseURL: CYBER_HOST
})
cyberport.interceptors.request.use((config) => {
    let token = localStorage.getItem("cyber_token")
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Accept-Language"] = localStorage.getItem("language") || "en_us";
    config.headers["App-Version"] = Number(localStorage.getItem("pwa_version"));
    return config;
});
cyberport.interceptors.response.use((res) => {
    return res;
}, (err) => {
    console.log(err.response)
    if (err.response.status === 412) {
        let version = err.response.data
        localStorage.setItem("pwa_version", version)
        location.reload()
    } else if (err.response.status === 401) {
        if (err.response.config.url === '/auth/update_tokens') {
            store.dispatch("app/logout")
        } else {
            store.dispatch("app/refreshToken")
        }
    }
    return Promise.reject(err)
});

const monthly = axios.create({
    baseURL: MONTHLY_HOST
})

monthly.interceptors.request.use((config) => {
    config.headers["App-Version"] = Number(localStorage.getItem("monthly_version"));
    return config;
});
monthly.interceptors.response.use((res) => {
    return res;
}, (err) => {
    console.log(err.response)
    if (err.response.status === 412) {
        let version = err.response.data
        localStorage.setItem("monthly_version", version)
        location.reload()
    }
    return Promise.reject(err)
});

const counter = axios.create({
    baseURL: COUNTER_HOST
})

export { base, pwa, chat, cyberport, monthly, counter };
