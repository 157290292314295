<template>
  <div class="tabs-content parking-history">
    <div class="d-flex align-center">
      <h3 class="main-title mr-8">{{ $t("settings") }}</h3>
      <v-tabs v-model="tab" class="ma-0 mr-6">
        <v-tab v-for="(item, index) in items" :key="index">{{ item }}</v-tab>
      </v-tabs>
    </div>
    <template v-if="tab === 0">
      <div class="my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center">
        <v-text-field
          v-model="search"
          autocomplete="off"
          solo
          class="parking-search mb-4 mb-sm-0 mr-4"
          :placeholder="$t('search_user')"
          style="max-width: 384px"
        ></v-text-field>

        <ReportDownloadForm
          @hideEmit="openReportForm = false"
          @showEmit="openReportForm = true"
          ref="modal"
          view="settings"
        />
        <v-btn class="main-btn px-4 py-2 ml-auto" @click="openAddForm"
          >{{ $t("add_user") }}</v-btn
        >
      </div>
      <UsersTable
        class="mb-5"
        :searchValue="search"
        ref="userTable"
        :openReportForm="openReportForm"
      />
      <UserForm :type="0" ref="userForm" />
    </template>
    <template v-if="tab === 1">
      <div class="my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center">
        <v-text-field
          v-model="search"
          autocomplete="off"
          solo
          class="parking-search mb-4 mb-sm-0 mr-4"
          :placeholder="$t('search_car')"
          style="max-width: 384px"
        />
        <v-btn
          text
          class="flat-btn ml-auto mr-4"
          @click="deleteSelected('blacklistTable')"
          >{{ $t("delete_selected") }}</v-btn
        >
        <v-btn class="main-btn px-4 py-2" @click="openAddForm">{{
          $t("blacklist_add")
        }}</v-btn>
      </div>
      <Blacklist class="mb-5" :searchValue="search" ref="blacklistTable" />
      <BlacklistForm ref="blacklistForm" />
    </template>
    <template v-if="tab === 2">
      <div class="my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center">
        <h4 class="main-title mr-8">{{ $t("pricing_plans") }}</h4>
        <v-btn
          text
          class="flat-btn ml-auto mr-4"
          @click="deleteSelected('pricingsTable')"
          >{{ $t("delete_selected") }}</v-btn
        >
        <v-btn class="main-btn px-4 py-2" @click="openAddForm">{{ $t("add") }}</v-btn>
      </div>
      <Pricings class="mb-5" ref="pricingsTable" />
      <PricingForm ref="pricingForm" :type="0" :selected="{}" />
    </template>
  </div>
</template>

<script>
export default {
  name: "Settings",
  components: {
    UsersTable: () => import("@/components/Settings/UsersTable"),
    UserForm: () => import("@/components/Settings/UserForm"),
    Blacklist: () => import("@/components/Settings/Blacklist"),
    BlacklistForm: () => import("@/components/Settings/BlacklistForm"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
    Pricings: () => import("@/components/Settings/Pricings"),
    PricingForm: () => import("@/components/Settings/PricingForm"),
  },
  data() {
    return {
      openReportForm: false,
      debouncedInput: "",
      timeout: null,
      updateTimer: null,
      tab: 0,
    };
  },
  computed: {
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
        }, 1000);
      },
    },
    items() {
      let items = [this.$t("users"), this.$t("blacklist")];
      if (this.$store.getters["app/hasMonthlyWA"]) items.push(this.$t("pricings"));
      return items;
    },
  },
  methods: {
    openAddForm() {
      let forms = ["userForm", "blacklistForm", "pricingForm"];
      this.$refs[forms[this.tab]].show();
    },
    deleteSelected(ref) {
      this.$refs[ref].deleteSelected();
    },
  },
};
</script>
