<template>
  <a>
    <v-img
      class="logo-img"
      :src="
        green === 'green'
          ? logoUrl
          : logoWhiteUrl
      "
      max-height="40"
      contain
      position="left center"
      alt="ParkingBnB"
    ></v-img>
  </a>
</template>

<script>
export default {
  name: "Logo",
  props: ["green"],
  computed: {
    logoWhiteUrl() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].logoWhiteUrl;
    },
    logoUrl() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].logoUrl;
    },
  },
};
</script>
